<template>
    <el-dialog
        title="请选择图标"
        :visible.sync="isShow"
        class="innerDialog"
        width="60%"
        append-to-body
    >
        <div class="dialog_main">
            <ul class="icon-list">
                <li
                    v-for="(item, index) in website.iconList"
                    class="icon_item"
                    :class="{
                        icon_selected: selectedIcon === item
                    }"
                    @click="selectedIcon = item"
                    @dblclick="selectIconDoubleClick(item)"
                    :key="index"
                >
                    <span>
                        <i :class="item"></i>
                        <span class="icon_title">{{ item }}</span>
                    </span>
                </li>
            </ul>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="isShow = false" size="small">取 消</el-button>
            <el-button @click="selectIconDoubleClick('')" size="small"
                >消除选择
            </el-button>
            <el-button type="primary" @click="iconSelected" size="small"
                >确 定
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "IconSelectDialog",
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters(["website"]),
        isShow: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit("update:visible", v);
            }
        }
    },
    data() {
        return {
            selectedIcon: ""
        };
    },
    methods: {
        selectIconDoubleClick(item) {
            this.selectedIcon = item;
            this.iconSelected();
        },
        iconSelected() {
            this.isShow = false;
            this.$emit("change-select", this.selectedIcon);
        }
    }
};
</script>
<style lang="scss" scoped>
.dialog_main {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .icon-list {
        overflow: hidden;
        list-style: none;
        padding: 0 !important;
        border: 1px solid #eee;
        border-radius: 4px;
        .icon_item {
            user-select: none;
            width: calc(100% / 5);
            float: left;
            text-align: center;
            height: 120px;
            line-height: 120px;
            color: #666;
            font-size: 13px;
            border-right: 1px solid #eee;
            border-bottom: 1px solid #eee;
            margin-right: -1px;
            margin-bottom: -1px;
            transition: color 0.15s linear;
            &:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
            }
            &:hover {
                color: #409eff;
                background-color: #eeeeee;
                cursor: pointer;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;
                i {
                    display: block;
                    font-size: 32px;
                    margin-bottom: 15px;
                }
                .icon_title {
                    display: inline-block;
                    padding: 0 3px;
                    height: 1em;
                }
            }
        }
        .icon_selected {
            color: #409eff !important;
            background-color: #eeeeee;
        }
    }
}
</style>
